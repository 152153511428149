import RecipeService from './recipe-service'
import SearchService from './search-service'

export type Clients = {
	cloudFunctions: firebase.default.functions.Functions,
	firestore: firebase.default.firestore.Firestore,
	storage: firebase.default.storage.Storage
}
const ServiceFactory = (clients: Clients) => {
	const recipeService = RecipeService(clients)
	const searchService = SearchService(clients)

	return {
		...recipeService,
		...searchService,
	}
}

export type Services = ReturnType<typeof ServiceFactory>
export default ServiceFactory