import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'
import 'firebase/app-check'
import 'firebase/performance'
import 'firebase/app-check'
import { firebaseConfig } from '../config/firebase'
import { useState } from 'react'

const app = firebase.initializeApp(firebaseConfig)

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
// config with https://www.google.com/recaptcha/admin/site/472824715
if (firebaseConfig.appCheckToken) {
	const appCheck = firebase.appCheck(app)
	appCheck.activate(new firebase.appCheck.ReCaptchaV3Provider(firebaseConfig.appCheckToken), true)
} else {
	console.info('Missing app check token')
}

const firestore = firebase.firestore()
const cloudFunctions = firebase.functions()
const storage = firebase.storage()
const perf = firebase.performance(app)
const auth = firebase.auth(app)

export default function useFirebase() {
	const [user, setUser] = useState<firebase.User | null>(null)

	auth.onAuthStateChanged((currentUser) => setUser(currentUser))

	return {
		firestore,
		cloudFunctions,
		storage,
		perf,
		auth,
		user
	}
}
