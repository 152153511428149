import { RecipeSummary } from 'adam-cartwright-domain'

export type RecipeSummaryViewModel = ReturnType<typeof RecipeSummaryToViewModel>
export function RecipeSummaryToViewModel(entity: RecipeSummary) {
	if (!entity.id || !Number.isInteger(+entity.cooking) || !Number.isInteger(+entity.preparation) || !Number.isInteger(+entity.difficulty)) {
		const err = new Error(`Your argument is invalid! ${JSON.stringify(entity, null, 2)}`)
		err.name = 'Mapping issue'
		throw err
	}
	const totalTime = +entity.cooking + +entity.preparation
	const hours = Math.floor(totalTime / 60)
	const min = +totalTime % 60
	const timeStr = hours
		? (min ? `${hours}h${min}min` : `${hours}h`)
		: `${min}min`

	return {
		id: entity.id,
		title: entity.title,
		image: entity.image,
		description: entity.description,
		totalTime: timeStr,
		difficulty: entity.difficulty,
	}
}

