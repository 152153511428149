import { Recipe, capitalize, getFraction } from 'adam-cartwright-domain'

export type IngredientViewModel = ReturnType<typeof IngredientViewModelFactory>;
export function IngredientViewModelFactory(text: string, quantity?: number, unit?: string) {
	const fromatQty = (qty: number) => qty < 1 ? getFraction(qty) : qty
	const format = () => quantity
		? `${fromatQty(quantity)} ${unit?.toLowerCase()} ${text}`
		: `${capitalize(text)}`
	return {
		text,
		quantity: quantity || Number(),
		unit: unit || String(),
		format
	}
}
export type IngredientCollectionViewModel = ReturnType<typeof IngredientCollectionViewModelFactory>;
export function IngredientCollectionViewModelFactory(list: IngredientViewModel[], name?: string) {
	return {
		name,
		toList: () => list
	}
}
export type RecipeViewModel = ReturnType<typeof RecipeViewModelFractory>
export function RecipeViewModelFractory(
	title: string,
	generalInfo: GeneralInfoViewModel,
	timing: TimingViewModel,
	image: string,
	description: string,
	socialLink: string,
	subRecipes: SubRecipeViewModel,
	notes: string[],
	likes: number
) {
	const timingConverted = {
		total: timeToString(timing.total),
		cooking: timeToString(timing.cooking),
		preparation: timeToString(timing.preparation),
	}
	return {
		title,
		generalInfo,
		timing: timingConverted,
		image,
		description,
		socialLink,
		subRecipes,
		notes,
		likes,
	}
}

export type GeneralInfoViewModel = ReturnType<typeof GeneralInfoToViewModel>
function timeToString(time: number) {
	const hours = Math.floor(time / 60)
	const min = time % 60

	const hoursStr = hours ? `${hours}h` : ''
	const minStr = min ? `${min}min` : ''
	const timeStr = `${hoursStr}${minStr}`
	return timeStr
}

export function GeneralInfoToViewModel(calories: number, difficulty: number, cost: number) {
	return {
		calories,
		difficulty,
		cost,
	}
}

export type TimingViewModel = ReturnType<typeof TimingToViewModel>
export function TimingToViewModel(preparation: number, cooking: number) {
	const total = preparation + cooking
	return {
		total,
		preparation,
		cooking
	}
}

export type SubRecipeViewModel = ReturnType<typeof SubRecipeToViewModel>
export function SubRecipeToViewModel(ingredients: IngredientCollectionViewModel[], methods: StepCollectionViewModel[]) {
	return {
		ingredients,
		methods
	}
}

export type StepViewModel = ReturnType<typeof StepToViewModel>
export function StepToViewModel(order: number, text: string) {
	if (!order || !Number(order)) throw new Error('\'Order\' must be greather than 0.')
	if (!text || !text.trim()) throw new Error('\'Text\' must have a value.')
	return {
		order,
		text,
		format: () => `${order} ${text}`
	}
}

export type StepCollectionViewModel = ReturnType<typeof StepCollectionToViewModel>
export function StepCollectionToViewModel(steps: StepViewModel[], name?: string) {
	return {
		name,
		toList: () => steps.sort((a, b) => (a.order > b.order) ? 1 : -1)
	}
}

export function RecipeToViewModel(entity: Recipe): RecipeViewModel {
	const ingredients: IngredientCollectionViewModel[] = []
	const methods: StepCollectionViewModel[] = []

	for (const recipe of entity.subRecipes) {
		ingredients.push(IngredientCollectionViewModelFactory(
			recipe.ingredients.map(i => IngredientViewModelFactory(i.name, i.quantity?.value, i.quantity?.unit)),
			recipe.name))
		methods.push(StepCollectionToViewModel(
			recipe.steps.map(s => StepToViewModel(s.order, s.text)),
			recipe.name
		))
	}

	const recipeModel = RecipeViewModelFractory(
		entity.title,
		GeneralInfoToViewModel(entity.generalInfo.calories, entity.generalInfo.difficulty, entity.generalInfo.cost),
		TimingToViewModel(entity.timing.preparation, entity.timing.cooking),
		entity.image,
		entity.description,
		entity.socialLink,
		SubRecipeToViewModel(ingredients, methods),
		entity.notes,
		entity.likes || 0
	)
	return recipeModel
}
