import { ThemeProvider } from '@emotion/react'
import { createTheme, StyledEngineProvider } from '@mui/material/styles'
import { ThemeProvider as MuiThemeProvider } from '@mui/styles'
import { StrictMode } from 'react'
import { BrowserRouter } from 'react-router-dom'
import AppContextProvider from './contexts/AppContext'
import useFirebase from './hooks/useFirebase'
import Routes from './router/routes'

const fontTitle = 'Lora'
const fontBody = 'karla'

const theme = createTheme({
	typography: {
		h1: { fontFamily: fontTitle },
		h2: { fontFamily: fontTitle },
		h3: { fontFamily: fontTitle },
		h4: { fontFamily: fontTitle },
		h5: { fontFamily: fontTitle },
		h6: { fontFamily: fontTitle },
		subtitle1: { fontFamily: fontTitle },
		subtitle2: { fontFamily: fontTitle },
		body1: { fontFamily: fontTitle },
		body2: { fontFamily: fontBody },
		caption: { fontFamily: fontBody },
		button: { fontFamily: fontTitle },
		overline: { fontFamily: fontBody },
	},
	palette: {
		mode: 'light',
		primary: {
			// light: will be calculated from palette.primary.main,
			// dark: will be calculated from palette.primary.main,
			// contrastText: will be calculated to contrast with palette.primary.main
			main: '#fff',
			contrastText: '#000'
		},
		secondary: {
			main: '#D19513',
			contrastText: '#000'
		},
		// Used by `getContrastText()` to maximize the contrast between
		// the background and the text.
		contrastThreshold: 3,
		// Used by the functions below to shift a color's luminance by approximately
		// two indexes within its tonal palette.
		// E.g., shift from Red 500 to Red 300 or Red 700.
		tonalOffset: 0.2,
	},
})

export default function App() {
	const firebaseHook = useFirebase()

	const element = document.getElementById('overlay')
	if (element?.parentNode) element.parentNode.removeChild(element)

	return (
		<StrictMode>
			<StyledEngineProvider injectFirst>
				<MuiThemeProvider theme={theme}>
					<ThemeProvider theme={theme}>
						<BrowserRouter>
							<AppContextProvider clients={{ ...firebaseHook }}>
								<Routes />
							</AppContextProvider>
						</BrowserRouter>
					</ThemeProvider>
				</MuiThemeProvider>
			</StyledEngineProvider>
		</StrictMode>
	)
}
