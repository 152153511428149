import { lazy, Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import SvgLoader from '../shared/SvgLoader'
import '../utils/stringExtension'

const Home = lazy(() => import('../pages/Home/Home'))
const RecipeList = lazy(() => import('../pages/RecipeList/RecipeList'))
const Recipe = lazy(() => import('../pages/Recipe/Recipe'))
const About = lazy(() => import('../pages/About/About'))
const Layout = lazy(() => import('../pages/Layout/Layout'))
const Admin = lazy(() => import('../pages/Admin'))

export const URI = {
	home: '/home',
	recipes: '/recipes',
	// blog: '/blog',
	about: '/about',
	goToRecipe: (id: string) => `/recipes/${id}`,
	goToSearchResult: (terms: string, filters: string[] = []) => `/recipes?terms=${terms}&filters=${filters.join(',')}`,
	admin: '/admin',
}

export default function Routes() {
	return (
		<Suspense fallback={<SvgLoader />}>
			<Switch>
				<Route exact path={URI.home}>
					<Home />
				</Route>
				<Route exact path={URI.recipes}>
					<Layout><RecipeList /></Layout>
				</Route>
				<Route exact path={`${URI.recipes}/:id`}>
					<Layout><Recipe /></Layout>
				</Route>
				{/* <Route exact path={URI.blog}>
					<Layout>BLOG</Layout>
				</Route> */}
				<Route exact path={URI.about}>
					<Layout><About /></Layout>
				</Route>
				<Route path={URI.admin}>
					<Layout><Admin /></Layout>
				</Route>
				<Redirect exact path="/" to={URI.home} />
			</Switch>
		</Suspense>
	)
}