import { Recipe, RecipeSummary } from 'adam-cartwright-domain'
import {
	RecipeSummaryToViewModel,
	RecipeSummaryViewModel,
	RecipeToViewModel,
	RecipeViewModel
} from '../viewModels'

type RecipeServiceClients = {
	cloudFunctions: firebase.default.functions.Functions,
	firestore: firebase.default.firestore.Firestore,
}
export default function RecipeService(clients: RecipeServiceClients) {
	const { cloudFunctions, firestore } = clients

	const addRecipe = async (recipe: Recipe) => {
		const cloudFn$ = cloudFunctions.httpsCallable('AddRecipe')
		const result = await cloudFn$(recipe)
		return result
	}

	const deleteRecipe = async (recipeId: string) => {
		const cloudFn$ = cloudFunctions.httpsCallable('DeleteRecipe')
		const result = await cloudFn$(recipeId)
		return result
	}

	const getRecipe = async (recipeId: string): Promise<RecipeViewModel | undefined> => {
		const query$ = firestore.collection('recipes')
			.doc(recipeId)
			.get()
			.then(snapshot => {
				const data = snapshot.data() as Recipe
				if (!data) return
				return RecipeToViewModel(data)
			})
		const result = await query$
		return result
	}

	const getRecipes = async (): Promise<Recipe[] | undefined> => {
		const query$ = firestore.collection('recipes')
			.get()
			.then(snapshot => {
				const recipeFeed: Recipe[] = []
				snapshot.forEach((doc) => {
					const data = { id: doc.id, ...doc.data() } as Recipe
					if (!data || !doc.id) return
					recipeFeed.push(data)
				})
				return recipeFeed
			})
		const result = await query$
		return result
	}

	const getMostPopular = async (): Promise<RecipeSummaryViewModel[] | undefined> => {
		const query$ = firestore.collection('recipes-feed')
			.where('active', '==', true)
			.orderBy('order', 'desc')
			.limit(12)
			.get()
			.then(snapshot => {
				const recipeFeed: RecipeSummaryViewModel[] = []
				snapshot.forEach((doc) => {
					const data = { id: doc.id, ...doc.data() } as RecipeSummary
					if (!data || !doc.id) return
					try {
						const summary = RecipeSummaryToViewModel(data)
						summary && recipeFeed.push(summary)
					} catch (error) {
						console.error(error)
					}
				})
				return recipeFeed
			})
		const result = await query$
		return result
	}

	return {
		addRecipe,
		deleteRecipe,
		getRecipe,
		getRecipes,
		getMostPopular,
	}
}
