import { createContext, useState, PropsWithChildren, useContext, Dispatch } from 'react'
import ServiceFactory, { Services, Clients } from '../api'
import { SearchResultViewModel } from '../viewModels'

type AppContextDefinition = Services & {
	searchItems: SearchResultViewModel[],
	setSearchItems: Dispatch<SearchResultViewModel[]>,
}

const AppContext = createContext<AppContextDefinition | null>(null)

export const useAppContext = () => {
	const context = useContext(AppContext)
	if (!context) throw Error('AppContext hasn\'t been provided!')
	return context
}

type Props = {
	clients: Clients
}
export default function AppContextProvider(props: PropsWithChildren<Props>) {

	const appServices = ServiceFactory({
		cloudFunctions: props.clients.cloudFunctions,
		firestore: props.clients.firestore,
		storage: props.clients.storage,
	})

	const [searchItems, setSearchItems] = useState<SearchResultViewModel[]>([])

	return (
		<AppContext.Provider value={{
			...appServices,
			searchItems,
			setSearchItems,
		}}>
			{props.children}
		</AppContext.Provider>
	)
}