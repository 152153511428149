import { ImageSizes } from '../constants'

type SearchServiceClients = {
	storage: firebase.default.storage.Storage,
}
export default function SearchService(clients: SearchServiceClients) {
	const { storage } = clients

	const getImage = async (imgName: string, imgSize: ImageSizes) => {
		try {
			const img = await storage
				.ref(`/pictures/${imgName}_${imgSize}.webp`)
				.getDownloadURL()
			return img
		}
		catch (err) {
			return // TODO: Set default image
		}
	}

	return {
		getImage,
	}
}
