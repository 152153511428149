import { capitalize, FilterType, Search, SearchItemType } from 'adam-cartwright-domain'
import { DocumentData } from '@firebase/firestore-types'

export type SearchResultViewModel = ReturnType<typeof SearchResultToViewModel>
export function SearchResultToViewModel(
	id: string,
	title: string,
	image: string,
	filters: FilterType[],
	ingredients: string[],
	description: string,
	type: SearchItemType,
	totalTime: number,
	difficulty: number,
) {
	if (!id || !title || !type || !Number.isInteger(+totalTime) || !Number.isInteger(+difficulty)) {
		const err = new Error('Your argument is invalid!')
		console.log(`Something wrong with recipe "${id}"`)
		throw err
	}
	const hours = Math.floor(+totalTime / 60)
	const min = +totalTime % 60
	const timeStr = hours
		? (min ? `${hours}h${min}min` : `${hours}h`)
		: `${min}min`
	return {
		id,
		title,
		image,
		filters: filters?.map(capitalize),
		ingredients,
		description,
		type,
		totalTime: timeStr,
		difficulty: +difficulty,
	}
}
export function SearchResultModelMapper(dto: Search & DocumentData) {
	const searchResultModel = SearchResultToViewModel(
		dto.id,
		dto.title,
		dto.image,
		dto.filters || [],
		dto.ingredients || [],
		dto.description,
		dto.type,
		dto.totalTime || 0,
		dto.difficulty || 0,
	)
	return searchResultModel
}