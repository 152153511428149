import Lottie from 'lottie-react-web'
import styles from './SvgLoader.module.scss'
import animationData from './lottie_animation.json'

export default function SvgLoader() {
	return (
		<div className={styles.loader}>
			<Lottie
				style={{
					maxWidth: '300px',
					margin: 'auto',
				}}
				options={{
					loop: true,
					autoplay: true,
					animationData: animationData,
				}}
			/>
		</div>
	)
}